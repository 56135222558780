@import url('./fonts.css'); 
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #160D1A;
  height: 100%;
  overflow-x: hidden;
}

.btn {
  background: linear-gradient(70deg, #A0F 46.32%, #F50 100.43%);
}

.btn-shadow {
  box-shadow: 0 4px 100px 0 rgba(170, 0, 255, 0.40);
}