html {
  font-family:
    -apple-system,
    -apple-system,
    "BlinkMacSystemFont",
    "Inter",
    "Roboto",
    sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family:
      -apple-system,
      -apple-system,
      "BlinkMacSystemFont",
      "Inter var",
      "Roboto",
      sans-serif;
  }
}

.serif {
  font-family:
    "RL Madena",
    -apple-system,
    -apple-system,
    "BlinkMacSystemFont",
    "Inter",
    "Roboto",
    sans-serif;
}

.gentium {
  font-family:
    "Gentium Basic",
    -apple-system,
    -apple-system,
    "BlinkMacSystemFont",
    "Inter",
    "Roboto",
    sans-serif;
}
.text-title-1-semibold,
.text-title-1-bold,
.text-title-1-heavy,
.text-title-2-regular,
.text-title-2-semibold,
.text-title-2-heavy,
.text-title-3-regular,
.text-title-3-medium,
.text-title-3-semibold {
  /*font-family: serif;*/
}

.text-title-1-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.072px;
}

.text-title-1-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.072px;
}

.text-title-1-heavy {
  font-style: normal;
  font-weight: 860;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.072px;
}

.text-title-2-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.45px;
}

.text-title-2-semibold {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.45px;
}

.text-title-2-heavy {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.45px;
}

.text-title-3-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
}

.text-title-3-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
}

.text-title-3-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.43px;
}

.text-title-3-bold {
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 129.412% */
  letter-spacing: -0.43px;
}

.text-headline-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.31px;
}

.text-headline-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.31px;
}

.text-headline-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.31px;
}

.text-text-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
}

.text-text-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
}

.text-text-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.23px;
}

.text-subhead-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
}

.text-subhead-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
}

.text-subhead-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
}

.text-subhead-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.15px;
}

.text-caption-1-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
}

.text-caption-1-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
}

.text-caption-1-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
}

.text-captioncaps-1-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;
  text-transform: uppercase;
}

.text-caption-2-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.text-caption-2-medium {
  font-style: normal;
  font-weight: 510;
  font-size: 12px;
  line-height: 14px;
}

.text-caption-2-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 12px;
  line-height: 14px;
}

.text-captioncaps-2-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
}

.text-caption-3-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.06px;
}

.text-captioncaps-3-semibold {
  font-style: normal;
  font-weight: 590;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.06px;
  text-transform: uppercase;
}

.text-caption-4-regular {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.17px;
}

.text-captioncaps-4-bold {
  font-style: normal;
  font-weight: 700;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.17px;
  text-transform: uppercase;
}
